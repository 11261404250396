exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-airbnb-clone-index-js": () => import("./../../../src/pages/airbnb-clone/index.js" /* webpackChunkName: "component---src-pages-airbnb-clone-index-js" */),
  "component---src-pages-all-features-of-all-in-one-delivery-app-solution-js": () => import("./../../../src/pages/all-features-of-all-in-one-delivery-app-solution.js" /* webpackChunkName: "component---src-pages-all-features-of-all-in-one-delivery-app-solution-js" */),
  "component---src-pages-all-features-of-food-delivery-app-solution-js": () => import("./../../../src/pages/all-features-of-food-delivery-app-solution.js" /* webpackChunkName: "component---src-pages-all-features-of-food-delivery-app-solution-js" */),
  "component---src-pages-all-features-of-grocery-delivery-app-solution-js": () => import("./../../../src/pages/all-features-of-grocery-delivery-app-solution.js" /* webpackChunkName: "component---src-pages-all-features-of-grocery-delivery-app-solution-js" */),
  "component---src-pages-all-features-of-handyman-booking-app-solution-js": () => import("./../../../src/pages/all-features-of-handyman-booking-app-solution.js" /* webpackChunkName: "component---src-pages-all-features-of-handyman-booking-app-solution-js" */),
  "component---src-pages-all-features-of-pharmacy-delivery-app-solution-js": () => import("./../../../src/pages/all-features-of-pharmacy-delivery-app-solution.js" /* webpackChunkName: "component---src-pages-all-features-of-pharmacy-delivery-app-solution-js" */),
  "component---src-pages-all-features-of-taxi-booking-app-solution-js": () => import("./../../../src/pages/all-features-of-taxi-booking-app-solution.js" /* webpackChunkName: "component---src-pages-all-features-of-taxi-booking-app-solution-js" */),
  "component---src-pages-all-features-of-vacation-rental-app-solution-js": () => import("./../../../src/pages/all-features-of-vacation-rental-app-solution.js" /* webpackChunkName: "component---src-pages-all-features-of-vacation-rental-app-solution-js" */),
  "component---src-pages-capabilities-js": () => import("./../../../src/pages/capabilities.js" /* webpackChunkName: "component---src-pages-capabilities-js" */),
  "component---src-pages-car-rental-script-all-features-js": () => import("./../../../src/pages/car-rental-script/all-features.js" /* webpackChunkName: "component---src-pages-car-rental-script-all-features-js" */),
  "component---src-pages-car-rental-script-index-js": () => import("./../../../src/pages/car-rental-script/index.js" /* webpackChunkName: "component---src-pages-car-rental-script-index-js" */),
  "component---src-pages-car-rental-script-pricing-js": () => import("./../../../src/pages/car-rental-script/pricing.js" /* webpackChunkName: "component---src-pages-car-rental-script-pricing-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-doordash-clone-index-js": () => import("./../../../src/pages/doordash-clone/index.js" /* webpackChunkName: "component---src-pages-doordash-clone-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instacart-clone-index-js": () => import("./../../../src/pages/instacart-clone/index.js" /* webpackChunkName: "component---src-pages-instacart-clone-index-js" */),
  "component---src-pages-pharmacy-delivery-script-index-js": () => import("./../../../src/pages/pharmacy-delivery-script/index.js" /* webpackChunkName: "component---src-pages-pharmacy-delivery-script-index-js" */),
  "component---src-pages-postmates-clone-index-js": () => import("./../../../src/pages/postmates-clone/index.js" /* webpackChunkName: "component---src-pages-postmates-clone-index-js" */),
  "component---src-pages-pricing-comparison-of-all-in-one-delivery-app-solution-js": () => import("./../../../src/pages/pricing-comparison-of-all-in-one-delivery-app-solution.js" /* webpackChunkName: "component---src-pages-pricing-comparison-of-all-in-one-delivery-app-solution-js" */),
  "component---src-pages-pricing-comparison-of-food-delivery-app-solution-js": () => import("./../../../src/pages/pricing-comparison-of-food-delivery-app-solution.js" /* webpackChunkName: "component---src-pages-pricing-comparison-of-food-delivery-app-solution-js" */),
  "component---src-pages-pricing-comparison-of-grocery-delivery-app-solution-js": () => import("./../../../src/pages/pricing-comparison-of-grocery-delivery-app-solution.js" /* webpackChunkName: "component---src-pages-pricing-comparison-of-grocery-delivery-app-solution-js" */),
  "component---src-pages-pricing-comparison-of-handyman-booking-app-solution-js": () => import("./../../../src/pages/pricing-comparison-of-handyman-booking-app-solution.js" /* webpackChunkName: "component---src-pages-pricing-comparison-of-handyman-booking-app-solution-js" */),
  "component---src-pages-pricing-comparison-of-pharmacy-delivery-app-solution-js": () => import("./../../../src/pages/pricing-comparison-of-pharmacy-delivery-app-solution.js" /* webpackChunkName: "component---src-pages-pricing-comparison-of-pharmacy-delivery-app-solution-js" */),
  "component---src-pages-pricing-comparison-of-taxi-booking-app-solution-js": () => import("./../../../src/pages/pricing-comparison-of-taxi-booking-app-solution.js" /* webpackChunkName: "component---src-pages-pricing-comparison-of-taxi-booking-app-solution-js" */),
  "component---src-pages-pricing-comparison-of-vacation-rental-app-solution-js": () => import("./../../../src/pages/pricing-comparison-of-vacation-rental-app-solution.js" /* webpackChunkName: "component---src-pages-pricing-comparison-of-vacation-rental-app-solution-js" */),
  "component---src-pages-schedule-a-free-demo-js": () => import("./../../../src/pages/schedule-a-free-demo.js" /* webpackChunkName: "component---src-pages-schedule-a-free-demo-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-space-rental-script-all-features-js": () => import("./../../../src/pages/space-rental-script/all-features.js" /* webpackChunkName: "component---src-pages-space-rental-script-all-features-js" */),
  "component---src-pages-space-rental-script-index-js": () => import("./../../../src/pages/space-rental-script/index.js" /* webpackChunkName: "component---src-pages-space-rental-script-index-js" */),
  "component---src-pages-space-rental-script-pricing-js": () => import("./../../../src/pages/space-rental-script/pricing.js" /* webpackChunkName: "component---src-pages-space-rental-script-pricing-js" */),
  "component---src-pages-taskrabbit-clone-index-js": () => import("./../../../src/pages/taskrabbit-clone/index.js" /* webpackChunkName: "component---src-pages-taskrabbit-clone-index-js" */),
  "component---src-pages-taxi-booking-script-index-js": () => import("./../../../src/pages/taxi-booking-script/index.js" /* webpackChunkName: "component---src-pages-taxi-booking-script-index-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-thumbtack-clone-index-js": () => import("./../../../src/pages/thumbtack-clone/index.js" /* webpackChunkName: "component---src-pages-thumbtack-clone-index-js" */),
  "component---src-pages-turo-clone-index-js": () => import("./../../../src/pages/turo-clone/index.js" /* webpackChunkName: "component---src-pages-turo-clone-index-js" */),
  "component---src-pages-uber-clone-index-js": () => import("./../../../src/pages/uber-clone/index.js" /* webpackChunkName: "component---src-pages-uber-clone-index-js" */),
  "component---src-pages-uber-eats-clone-index-js": () => import("./../../../src/pages/uber-eats-clone/index.js" /* webpackChunkName: "component---src-pages-uber-eats-clone-index-js" */),
  "component---src-pages-vrbo-clone-index-js": () => import("./../../../src/pages/vrbo-clone/index.js" /* webpackChunkName: "component---src-pages-vrbo-clone-index-js" */)
}

